<template>
  <div class="container">
    <template v-if="verification === 'success'">
      <Icon class="icon success mb-20" type="md-checkmark-circle" />
      <h1 class="mb-20">Successfully accepted the invitation</h1>
      <router-link to="/">
        <Button type="primary">Back To Home</Button>
      </router-link>
    </template>

    <template v-if="verification === 'fail'">
      <Icon class="icon fail mb-20" type="md-close-circle" />
      <h1 class="mb-20">Invitation Failed</h1>
      <router-link to="/">
        <Button type="primary">Back To Home</Button>
      </router-link>
    </template>

    <Spin fix v-if="verification === 'waiting'"></Spin>
  </div>
</template>

<script>
import api from "@/api";
const { _verifyInvite } = api;

export default {
  data() {
    return {
      verification: "waiting" // waiting success fail
    };
  },
  created() {
    const token = this.$route.query.token;
    if (token) {
      _verifyInvite({
        token
      })
        .then(response => {
          console.log(response);
          this.verification = "success";
          this.$router.push("/user-action/welcome-invite");
        })
        .catch(error => {
          this.verification = "fail";
          console.log(error);
        });
    } else {
      this.verification = "fail";
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .icon {
    font-size: 100px;
    &.success {
      color: #19be6b;
    }
    &.fail {
      color: #ed4014;
    }
  }
}
</style>
